import React from "react";
import { Link, NavLink } from "react-router-dom";
import WorldNews from "./World.js";
import BusinessNews from "./Business.js";
import InternationalNews from "./Countries.js";
import EnvironmentalNews from "./Environment.js";
import HealthNews from "./health.js";
import IndiaNews from "./India.js";
import PoliticalNews from "./Politics.js";
import ScienceNews from "./Science.js";
import SportsNews from "./Sports.js";
import TechnologyNews  from "./Technology.js";
import './CoNav.css';

const CoNav = () => {

    return (
        <>
<nav className="custom-navigation">
      <ul className="custom-navigation__list">
        <li className="custom-navigation__item">
          <Link className="custom-navigation__link" to="/WorldNews">World</Link>
        </li>
        <li className="custom-navigation__item">
          <Link className="custom-navigation__link" to="/IndiaNews">India</Link>
        </li>
        <li className="custom-navigation__item">
          <Link className="custom-navigation__link" to="/TechnologyNews">Technology</Link>
        </li>
        <li className="custom-navigation__item">
          <Link className="custom-navigation__link" to="/HealthNews">Health</Link>
        </li>
        <li className="custom-navigation__item">
          <Link className="custom-navigation__link" to="/InternationalNews">Countries</Link>
        </li>
        <li className="custom-navigation__item">
          <Link className="custom-navigation__link" to="/BusinessNews">Business</Link>
        </li>
        <li className="custom-navigation__item">
          <Link className="custom-navigation__link" to="/EnvironmentalNews">Environment</Link>
        </li>
        <li className="custom-navigation__item">
          <Link className="custom-navigation__link" to="/SportsNews">Sports</Link>
        </li>
        <li className="custom-navigation__item">
          <Link className="custom-navigation__link" to="/PoliticalNews">Politics</Link>
        </li>
      </ul>
    </nav>

        </>


    )
}

export default CoNav;