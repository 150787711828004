import React from "react";

const footerStyle = {
  backgroundColor: '#21ED76', // A dark green background
  color: '#fff', // White text color
  padding: '20px 10px', // Padding for top/bottom and left/right
  textAlign: 'center', // Center align text
  fontSize: '14px', // Smaller font size for mobile
};

function Footer() {
  return (
    <footer style={footerStyle}>
      <div>
        <h4>Get To Know!</h4>
        <a href="#about" className="text-white">About us</a> | 
        <a href="#contact">Contact us</a> | 
        <a href="#careers">Careers</a>
      </div>
      <div>
        <h4>Assistance</h4>
        <a href="#payments">Payments</a> | 
        <a href="#shipping">Shipping</a> | 
        <a href="#refunds">Cancel and Refunds</a>
      </div>
      <div>
        <h4>Sibling Companies</h4>
        <a href="#ekkhoj-tech">Ekkhoj Technology</a> | 
        <a href="#ekkhoj-ads">Ekkhoj Advertising Agency</a>
      </div>
      <div>
        <h4>Consumer Policies</h4>
        <a href="#terms">Terms and Conditions</a> | 
        <a href="#privacy">Privacy</a>
      </div>
      <div>
        <h4>Post To Us!</h4>
        <p>
          JCRS SANIL Enterprise, Town Badlapur, Post Badlapur<br />
          District Jaunpur Uttar Pradesh<br />
          India, 222125
        </p>
      </div>
      <div>
        <h4>Make Money With Us!</h4>
        <a href="#sell">Sell with Workhelper</a> | 
        <a href="#partner">Become Advertising Partner</a>
      </div>
      <div>
        <h4>Connect With Us!</h4>
        <a href="#twitter">Twitter</a> | 
        <a href="#facebook">Facebook</a> | 
        <a href="#instagram">Instagram</a>
      </div>
    </footer>
  );
}

export default Footer;
