import React, { useState, useEffect } from "react";
import { database } from "../../../Components/DBConnect";
import { collection, query, getDocs, orderBy, limit, startAfter } from "firebase/firestore";
import './Cards.css';

const Card = () => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastVisible, setLastVisible] = useState(null);
    const postsPerPage = 10;

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const postsCollection = collection(database, 'Blogs_Contents');
                let q = query(postsCollection, orderBy("createdAt", "desc"), limit(postsPerPage));
                if (lastVisible) {
                    q = query(postsCollection, orderBy("createdAt", "desc"), startAfter(lastVisible), limit(postsPerPage));
                }
                const postsSnapshot = await getDocs(q);
                const postData = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPosts(postData);
                setLastVisible(postsSnapshot.docs[postsSnapshot.docs.length - 1]);
            } catch (error) {
                console.error("Error getting documents:", error);
            }
        };

        fetchPosts();
    }, [currentPage]);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    return (
        <div className="row">
            {posts.map(post => (
                <div key={post.id} className="col-4">
                    <div className="p-card">
                        <div className="p-card__content">
                            <img 
                                className="p-card__image" 
                                alt={post.title || "Post Image"} 
                                height="185" 
                                width="330" 
                                src={post.imageUrl || "https://assets.ubuntu.com/v1/36f1139e-Design-and-Web-Team-Blog.jpg"} 
                            />
                            <h4>
                                <a href={`/post/${post.id}`} dangerouslySetInnerHTML={{ __html: post.title || 'Untitled' }} />
                            </h4>
                            <p className="u-no-padding--bottom" dangerouslySetInnerHTML={{ __html: `${post.content.slice(0, 100)}...` }}></p>
                            
                            {/* Time and Writer Details */}
                            <p className="text-light-grey">
                                <small>By: {post.WriterName || 'Unknown'}</small><br/>
                                <small>{new Date(post.createdAt.seconds * 1000).toLocaleString()}</small>
                            </p>
                        </div>
                    </div>
                </div>
            ))}
            {/* Pagination Buttons */}
            <div className="col-12 pagination-container">
                <button 
                    className="pagination-btn" 
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <button 
                    className="pagination-btn" 
                    onClick={handleNextPage}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default Card;
