// src/Components/Home.js
import React, { useState } from 'react';
import './Home.css';
import { Outlet, Link } from "react-router-dom"; // Ensure you have some CSS for styling
import Carousel from './subComponents/carousal';
import Card from './subComponents/Cards/Card.js';
import CoNav from './subComponents/CoNav/CoNav.js';
import HeadManager from '../Components/HeadManager.js';

const Home = () => {
 



  return (<div>      
    <HeadManager 
    title="Welcome to Mobile Version of Workhelper Website!" 
    description="Tech News, Breaking News, Video Downloader, Converting PNG to PDF, JPG to PDF, Watching Live TV, General Utility Website for free for the people of the world." 
    canonicalUrl={window.location.href} 
  />


  <Outlet/>
  <CoNav/>
 <Carousel/>

 <div>
  <Card/>
 </div>

 
         </div>
  );
};

export default Home;
