import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { database } from '../../Components/DBConnect.js'; // Ensure this points to your Firebase config
import HeadManager from '../../Components/HeadManager'; // Adjust the path based on where you store this file
import './PostDetails.css';

const PostDetails = () => {
    const { postId } = useParams(); // Extract postId from the URL
    const [post, setPost] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                // Reference to the document in Blogs_Contents collection
                const postDoc = doc(database, 'Blogs_Contents', postId);
                const docSnap = await getDoc(postDoc);
                if (docSnap.exists()) {
                    setPost({
                        id: docSnap.id,
                        title: docSnap.data().title,
                        description: docSnap.data().description || '', // Default to empty string if undefined
                        content: docSnap.data().content,
                        createdAt: docSnap.data().createdAt
                    });
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching post:', error);
            }
        };

        fetchPost();
    }, [postId]);

    // Function to truncate description to the first 70 words
    const truncateDescription = (description = '', wordLimit = 70) => {
        const words = description.replace(/<[^>]+>/g, '').split(/\s+/);
        return words.slice(0, wordLimit).join(' ') + (words.length > wordLimit ? '...' : '');
    };

    return (
        <div>
            {post && (
                <HeadManager 
                    title={post.title.replace(/<[^>]+>/g, '')}  // Stripping HTML tags from title
                    description={truncateDescription(post.description)} // Truncate description
                    canonicalUrl={`https://m.workhelper.shop/post/${post.id}`} 
                />
            )}
            <div className="post-details p-card--highlighted u-align-text-center">
                {post ? (
                    <>
                        <h1 className="post-title p-heading--2" dangerouslySetInnerHTML={{ __html: post.title }} />
                        <div className="post-content p-text--normal" dangerouslySetInnerHTML={{ __html: post.content }} />
                        <p className="post-date p-text--small">
                            Posted on: {new Date(post.createdAt.seconds * 1000).toLocaleDateString()}
                        </p>
                    </>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
};

export default PostDetails;
