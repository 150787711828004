import React, { useState } from 'react';
import './styles.css';  // Ensure you import the CSS file
import logo from '../assets/logo.png';
import { NavLink } from 'react-router-dom';

function Header() {
    const [isColumnVisible, setIsColumnVisible] = useState(false);

    const toggleColumn = () => {
        setIsColumnVisible(!isColumnVisible);
    };

    // Function to collapse the popup after clicking a link
    const handleLinkClick = () => {
        setIsColumnVisible(false);
    };

    return (
        <header>
            <div className="header-container">
                <div className='logo'><img src={logo} alt="Workhelper Website" /></div> 
                <ul>
                <i id='icn01' onClick={toggleColumn} class="fa-duotone fa-solid fa-bars"/>
                </ul>
                {isColumnVisible && <div className="popup-column">
                    <nav>
                        <ul>
                            <li className='list'>
                                <NavLink to="/" onClick={handleLinkClick}><p id='p01'>Home</p></NavLink>
                            </li>
                            <li className='list'>
                                <NavLink to="/Downloader" onClick={handleLinkClick}><p id='p01'>Downloads</p></NavLink>
                            </li>
                            <li className='list'>
                                <NavLink to="/Converter" onClick={handleLinkClick}><p id='p01'>Convert</p></NavLink>
                            </li>
                            <li className='list'>
                                <NavLink to="/LiveTV" onClick={handleLinkClick}><p id='p01'>LiveTV</p></NavLink>
                            </li>
                            <li className='list' onClick={handleLinkClick}>
                                <p id='p01'>Quit</p>
                            </li>
                        </ul>
                    </nav>
                </div>}
            </div>
        </header>
    );
}

export default Header;
