import React from 'react';
import './Home.css';
import { Outlet, Link } from "react-router-dom"; // Ensure you have some CSS for styling
import HeadManager from '../Components/HeadManager';


const Converter = () => {

return (
    <div>
        <HeadManager 
        title="Convert Files Effortlessly | PNG to PDF, JPG, SVG, and More | Online Converter" 
        description="Use our Converter to easily convert files between formats like PNG to PDF, PNG to JPG, JPEG to SVG, and more. Fast, free, and reliable—your all-in-one online file conversion tool." 
        canonicalUrl={window.location.href} 
      />

        Hello Here you can Convert You File at High Speed!
    </div>
)

}

export default Converter;